import React  from 'react'

function SweatpantsForm() {

  return (
    <form className='qrForm-url'>
      <div className='form-floating mb-3'>
      <a href="https://sweatpantsqr.com/" target="_blank" rel="noreferrer">
        <button className="btn btn-dark mx-2" type="button">
          Create Custom QR Merch with SweatpantsQR
        </button>
      </a>
      </div>
    </form>
  )
}

export default SweatpantsForm
